import request from '@/plugins/request';

export function getPdf(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/esignDetail',
		method: 'get',
		params: data
	});
}

export function sendSignMobileCodeReq(data) {
	return request({
		url: '/storagemanage/api/esign/sendSignMobileCode3rd',
		method: 'get',
		params: data
	});
}

export function sign(data) {
	return request({
		url: '/storagemanage/api/esign/tenantSignature',
		method: 'post',
		data: data
	});
}

export function sendFaceAuthByIDNo(params) {
    return request({
		url: '/storagemanage/api/esign/sendFaceAuthByIDNo',
    method: 'get',
    params: params,
    });
}
